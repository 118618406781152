import React from "react"
import { graphql } from "gatsby"
import { Layout } from "../components/Layout/Layout"
import { PageTitle } from "../components/PageTitle/PageTitle"

interface DatenschutzProps {
  data: {
    cms: {
      page: {
        title: string
        legalPagesFields: {
          content: string
        }
      }
    }
  }
}

const Datenschutz: React.FC<DatenschutzProps> = ({ data }) => {
  return (
    <Layout background={"bg-homepageBackground"} bordertop="border-t-2">
      <div className="flex w-11/12 flex-col items-center pb-64 lg:w-10/12">
        <PageTitle styleForLegalPages={true}>{data.cms.page.title}</PageTitle>
        <div
          style={{ maxWidth: 1302 }}
          className="rich-text-3 flex w-full flex-col bg-homepageBackground text-headings md:px-20 lg:pl-60 lg:pr-20"
          dangerouslySetInnerHTML={{
            __html: data.cms.page.legalPagesFields.content,
          }}
        />
      </div>
    </Layout>
  )
}
export default Datenschutz
export const query = graphql`
  {
    cms {
      page(id: "/datenschutz2", idType: URI) {
        title
        legalPagesFields {
          content
        }
      }
    }
  }
`
